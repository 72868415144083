<template>
  <div>
    <div>
      User Type: {{ UserType }}
    </div>
    <div
      v-for="(scope) in allScopes" 
      :key="scope"
    >
      <div>{{ scope }}</div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      UserType: "",
    }
  },
  computed: {
    allScopes: function() {
      return this.$store.state.scopes.UserScopes;
    }
  },
  async mounted() {
    this.UserType = localStorage.getItem("userType");
    // We need to load cases for UserType (User, Client, Investigator)
  },
}
</script>

<style>

</style>
